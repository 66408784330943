/**
 * Оставляет только те фильтры, у которых есть значения
 * @param {object} filters
 * @returns object
 */
function getNotEmptyFilters(filters) {
  return Object.fromEntries(
    Object.entries(filters).filter(([key, value]) =>
      Array.isArray(value) ? value.length : value
    )
  );
}

/**
 * Форматирует значения фильтров
 * @param {object} filters
 * @param {object} config
 * @returns array
 */
export function getFormattedFilters(filters, config) {
  const notEmptyFilters = Object.entries(getNotEmptyFilters(filters));
  const formattedFilters = notEmptyFilters.map(([key, value]) => {
    if (config[key].changeValue) {
      return { ...config[key], value: config[key].changeValue(value) };
    }
    return { ...config[key], value };
  });
  return formattedFilters;
}
