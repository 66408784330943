<template>
  <div>
    <h2 class="mb-4">Партнеры</h2>
    <AppTable
      :headers="$options.headers"
      :items="partners.data"
      :pagination="pagination"
      :items-server-length="partners.paginatorInfo.total"
      :loading="$apollo.queries.partners.loading"
      @change-visible-headers="onChangeVisibleHeaders"
      @change-page="onChangePage"
      @change-per-page="onChangePerPage"
    >
      <template #header>
        <v-btn
          outlined
          text
          small
          color="primary"
          :to="{ name: 'admin/partner-create' }"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          добавить
        </v-btn>
      </template>
      <template #item._actions="{ item }">
        <div class="d-flex justify-center">
          <v-btn small icon color="red" @click="onClickEdit(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template #body.prepend>
        <td v-show="visibleHeaders.includes($options.HEADER_FIELDS_VALUES.id)">
          <v-text-field
            v-model="filters.id"
            label="ID"
            dense
            single-line
            hide-details
          />
        </td>
        <td
          v-show="visibleHeaders.includes($options.HEADER_FIELDS_VALUES.name)"
        >
          <v-text-field
            v-model="filters.name"
            label="Подпись"
            dense
            single-line
            hide-details
          />
        </td>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { PartnersList } from "@/graphql/queries/Partners.graphql";
import AppTable from "@components/base/app-table/app-table";
import { debounce } from "@/utils";
import { BASE_DEBOUNCE_DELAY } from "@/utils/constants";
import { QueryPartnersOrderByColumn, SortOrder } from "@generated/graphql";
import { getFormattedFilters } from "@/utils/filters";
import { PARTNERS_LIST_FILTER_CONFIG } from "@/domain/partner";
import { Routes } from "@/types/core";

export default {
  components: {
    AppTable,
  },

  apollo: {
    partners: {
      query: PartnersList,
      variables() {
        return {
          first: 5,
          page: 1,
          orderBy: [
            { column: QueryPartnersOrderByColumn.Id, order: SortOrder.Desc },
          ],
        };
      },
    },
  },

  headers: [
    { text: "ID", value: "id", width: 100, sortable: false },
    { text: "Подпись", value: "name", sortable: false },
    { text: "Действия", value: "_actions", sortable: false, width: "50" },
  ],

  HEADER_FIELDS_VALUES: {
    id: "id",
    name: "name",
  },

  data() {
    return {
      pagination: {
        page: 1,
        first: 5,
      },
      partners: {
        paginatorInfo: {
          perPage: 5,
          currentPage: 1,
          total: 0,
          count: 0,
          hasMorePages: true,
        },
        data: [],
      },
      visibleHeaders: [],
      filters: { id: "", name: "" },
    };
  },

  watch: {
    filters: {
      handler() {
        this.onChangeFilters();
      },
      deep: true,
    },
  },

  methods: {
    onChangeFilters: debounce(async function () {
      this.pagination.page = 1;

      await this.$apollo.queries.partners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
        where: {
          AND: getFormattedFilters(this.filters, PARTNERS_LIST_FILTER_CONFIG),
        },
      });
    }, BASE_DEBOUNCE_DELAY),

    async onChangePage(page) {
      this.pagination.page = page;

      await this.$apollo.queries.partners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
      });
    },

    async onChangePerPage(perPage) {
      this.pagination.first = perPage;
      this.pagination.page = 1;

      await this.$apollo.queries.partners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
      });
    },

    onClickEdit(item) {
      this.$router.push({
        name: Routes["admin/partner-edit"],
        params: { id: item.id },
      });
    },

    onChangeVisibleHeaders(headers) {
      this.visibleHeaders = headers.map((h) => h.value);
    },
  },
};
</script>
