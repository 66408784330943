import { QueryPartnersWhereColumn, SqlOperator } from "@/generated/graphql";

export const PARTNERS_LIST_FILTER_CONFIG = {
  id: {
    column: QueryPartnersWhereColumn.Id,
    operator: SqlOperator.Eq,
  },
  name: {
    column: QueryPartnersWhereColumn.Name,
    operator: SqlOperator.Ilike,
    changeValue: (val: string) => `%${val.trim()}%`,
  },
};
